<template>
  <b-sidebar id="new-trade-create" ref="tradeQuote" :hidden="sidebarHiddden" sidebar-class="sidebar-lg" width="80%" no-close-on-backdrop no-close-on-esc bg-variant="white" right shadow backdrop no-header>
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">New Purchase Quote</h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="closeSidebar()" />
      </div>

      <trade-quote :saleQuoteData.sync="saleQuoteData" :tradeDataChildData.sync="tradeDataChildData" :hide="hide" :pageReload.sync="pageReload" v-if="pageReload"></trade-quote>
    </template>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BFormTextarea, BButton } from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import Ripple from 'vue-ripple-directive';

import vSelect from 'vue-select';
import TradeQuote from './trade-quote/TradeQuote.vue';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    vSelect,
    TradeQuote,
  },
  directives: {
    Ripple,
  },

  watch: {
    pageReload: function(val) {
      if (val == false) {
        setTimeout(() => {
          this.pageReload = true;
        }, 100);
      }
    },

    tradeDataId: {
      handler: function(x, y) {
        if (x != null) {
          this.tradeDataChildData = {
            index: x.index,
            id: x.id,
            vehicleId: x.vehicleId ? x.vehicleId : null,
            condition: x.condition ? x.condition : null,
            mileageUnit: x.mileageUnit ? x.mileageUnit : null,
            extColour: x.extColour ? x.extColour : null,
            modelYear: x.modelYear ? x.modelYear : null,
            mileageValue: x.mileageValue ? x.mileageValue : null,
            intColour: x.intColour ? x.intColour : null,
            make: x.make ? x.make : null,
            body: x.body ? x.body : null,
            fuel: x.fuel ? x.fuel : null,
            model: x.model ? x.model : null,
            seatingCapacity: x.seatingCapacity ? x.seatingCapacity : null,
            drivetrain: x.drivetrain ? x.drivetrain : null,
            trim: x.trim ? x.trim : null,
            numberOfDoors: x.numberOfDoors ? x.numberOfDoors : null,
            transmmission: x.transmmission ? x.transmmission : null,
            stockNumber: x.stockNumber ? x.stockNumber : null,
            vinNo: x.vinNo ? x.vinNo : null,
            origenalMarket: x.origenalMarket ? x.origenalMarket : null,
            msrpCurrency: x.msrpCurrency ? x.msrpCurrency : null,
            baseMsrp: x.baseMsrp ? x.baseMsrp : null,
            msrpOptions: x.msrpOptions ? x.msrpOptions : [],
            accessioreOptions: x.accessioreOptions ? x.accessioreOptions : [],
            vehicleDeclearation: [
              x.vehicleDeclearation[0] ? x.vehicleDeclearation[0] : null,
              x.vehicleDeclearation[1] ? x.vehicleDeclearation[1] : null,
              x.vehicleDeclearation[2] ? x.vehicleDeclearation[2] : null,
              x.vehicleDeclearation[3] ? x.vehicleDeclearation[3] : null,
              x.vehicleDeclearation[4] ? x.vehicleDeclearation[4] : null,
              x.vehicleDeclearation[5] ? x.vehicleDeclearation[5] : null,
              x.vehicleDeclearation[6] ? x.vehicleDeclearation[6] : null,
            ],
            vehicleDeclearationText: x.vehicleDeclearationText ? x.vehicleDeclearationText : null,

            vehicleTitleType: x.vehicleTitleType ? x.vehicleTitleType : null,
            appraisedVehiclePrice: x.appraisedVehiclePrice ? x.appraisedVehiclePrice : null,
            creditDue: x.appraisedVehiclePrice ? x.appraisedVehiclePrice : null,

            appraisalNotes: x.appraisalNotes ? x.appraisalNotes : null,
            appraisalNotes: x.appraisalNotes ? x.appraisalNotes : null,
            beforeTax: x.beforeTax ? x.beforeTax : null,
            payoutType: x.payoutType ? x.payoutType : null,
            unitlDate: x.unitlDate ? x.unitlDate : null,
            payoutGstRate: null,
            payoutGstAmount: null,
            payoutTotalAmount: null,
            quoteGallery: x.quoteGallery ? x.quoteGallery : [],
            deleteImages: [],
            lienCustomerId: x.lienCustomerId ? x.lienCustomerId : null,

            lienHolder: {
              sellerType: x.lienHolder.sellerType ? x.lienHolder.sellerType : null,
              phoneNumber: x.lienHolder.phoneNumber ? x.lienHolder.phoneNumber : null,
              firstName: x.lienHolder.firstName ? x.lienHolder.firstName : null,
              lastName: x.lienHolder.lastName ? x.lienHolder.lastName : null,
              email: x.lienHolder.email ? x.lienHolder.email : null,
              unitNumber: x.lienHolder.unitNumber ? x.lienHolder.unitNumber : null,
              provence: x.lienHolder.provence ? x.lienHolder.provence : null,
              streetAddress: x.lienHolder.streetAddress ? x.lienHolder.streetAddress : null,
              city: x.lienHolder.city ? x.lienHolder.city : null,
              postalCode: x.lienHolder.postalCode ? x.lienHolder.postalCode : null,
              country: x.lienHolder.country ? x.lienHolder.country : null,
              gst: x.lienHolder.gst ? x.lienHolder.gst : null,
              pst: x.lienHolder.pst ? x.lienHolder.pst : null,
              dealer: x.lienHolder.dealer ? x.lienHolder.dealer : null,
              driverLicenseNumber: x.lienHolder.driverLicenseNumber ? x.lienHolder.driverLicenseNumber : null,
              otherIdNumber: x.lienHolder.otherIdNumber ? x.lienHolder.otherIdNumber : null,
              dateOfBirth: x.lienHolder.dateOfBirth ? x.lienHolder.dateOfBirth : null,
            },
          };
        }
      },
    },
  },

  props: {
    saleQuoteData: {
      type: Object,
      required: true,
    },
    tradeDataId: {
      required: false,
    },
  },

  methods: {
    closeSidebar() {
      this.$emit('update:tradeDataId', null);
      this.tradeDataChildData = null;
      this.$root.$emit('bv::toggle::collapse', 'new-trade-create');
      this.pageReload = false;

      // this.$emit('update:tradeDataId', null);
      // this.$root.$emit('bv::toggle::collapse', 'new-trade-create');
    },
  },

  setup() {
    const customer = ref({
      name: '',
      email: '',
      address: '',
      country: '',
      contact: '',
    });

    var tradeDataChildDataModel = {
      id: null,
      inventoryId: null,
      condition: null,
      mileageUnit: null,
      extColour: null,
      modelYear: null,
      mileageValue: null,
      intColour: null,
      make: null,
      body: null,
      fuel: null,
      model: null,
      seatingCapacity: null,
      drivetrain: null,
      trim: null,
      numberOfDoors: null,
      transmmission: null,
      stockNumber: null,
      vinNo: null,
      origenalMarket: null,
      msrpCurrency: null,
      baseMsrp: null,
      msrpOptions: [],
      accessioreOptions: [],
      vehicleDeclearation: [null, null, null, null, null, null, null],
      vehicleDeclearationText: null,
      vehicleTitleType: null,
      appraisedVehiclePrice: null,
      creditDue: null,

      appraisalNotes: null,
      payoutType: null,
      unitlDate: null,
      beforeTax: null,
      payoutGstRate: null,
      payoutGstAmount: null,
      payoutTotalAmount: null,
      quoteGallery: [],
      deleteImages: [],
      get_lien_customer: null,
      lienCustomerId: null,

      lienHolder: {
        sellerType: null,
        phoneNumber: null,
        firstName: null,
        lastName: null,
        email: null,
        unitNumber: null,
        provence: null,
        streetAddress: null,
        city: null,
        postalCode: null,
        country: null,
        gst: null,
        pst: null,
        dealer: null,
        driverLicenseNumber: null,
        otherIdNumber: null,
        dateOfBirth: null,
        contactPerson: null,
        contactInfo: null,
      },
    };

    return {
      customer,
      sidebarHiddden: false,
      pageReload: true,
      tradeDataChildData: tradeDataChildDataModel,
    };
  },
};
</script>
