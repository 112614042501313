<template>
  <div>
    <validation-observer ref="vehicleRules">
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Trade-Ins</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <!-- <b-col :cols="saleQuoteData.inventoryId == null ? '10' : '12'">
        <b-form-group label="Vehicle" label-for="vehicle">
          <v-select v-model="saleQuoteData.inventoryId" :options="dropInventorys" :reduce="(val) => val.id" label="value" input-id="customer-data" :clearable="true"> </v-select>
        </b-form-group>
      </b-col> -->

      <!-- :before-change="validationForm1" -->
    </validation-observer>

    <new-trade-create :saleQuoteData.sync="saleQuoteData" :tradeDataId.sync="tradeDataId" v-model="isTaskHandlerSidebarActive" :hide="hide"></new-trade-create>

    <existing-trade-select :saleQuoteData.sync="saleQuoteData" :tradeAllQuoteData.sync="tradeAllQuoteData" :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"></existing-trade-select>

    <b-row>
      <b-col cols="6" class="text-left">
        <b-button v-b-toggle.new-trade-create variant="relief-dark" v-if="saleQuoteData.inventoryId" block class="btn-icon mb-2">
          New Purchase Quote
        </b-button>
      </b-col>

      <b-col cols="6" class="text-left">
        <b-button variant="relief-dark" @click="handleTaskClick()" v-if="saleQuoteData.inventoryId" block class="btn-icon mb-2">
          Existing Trade-in Quotes
        </b-button>
      </b-col>

      <b-col cols="12" v-if="!saleQuoteData.inventoryId" class="text-center">
        <span class="text-danger">First select the vehicle to be sold</span>
      </b-col>

      <b-col cols="12" v-if="saleQuoteData.trades.length == 0 && saleQuoteData.winTrades.length == 0 && saleQuoteData.inventoryId" class="text-center">
        <span class="text-danger">There is no vehicle or purchase quote for trade-ins</span>
      </b-col>
      <!-- <b-img width="250px" src="@/assets/images/no-car.png" /> -->
      <hr class="mb-2" />

      <b-col v-for="(item, index) in saleQuoteData.trades" :key="item.id" cols="3">
        <feather-icon icon="XIcon" v-b-tooltip.hover.top="'Delete'" size="20" class="deleteButton" @click="tradeDelete(item, index)" />
        <!-- require('@/assets/images/no-car.png') -->

        <b-card @click="tradeClick(item, index)" img-alt="Profile Cover Photo" img-top no-body style="height: 230px;" class="card-profile p-1">
          <h5 class="mt-1" v-if="item.inventoryId">
            {{ dropInventorys.find((x) => x.id === item.inventoryId).value }}
          </h5>

          <h5 class="mt-1" v-else>
            Newly Added Vehicle
          </h5>

          <hr class="mb-2" />

          <!-- follower projects rank -->
          <div class="text-center  justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder">Appraised Vehicle Price</h6>
              <h3 class="mb-1">${{ item.appraisedVehiclePrice ? formatPrice(item.appraisedVehiclePrice) : 0.0 }}</h3>
            </div>

            <div>
              <h6 class="text-muted font-weight-bolder">Credit Due</h6>
              <h4 class="mb-1">${{ item.creditDue ? formatPrice(item.creditDue) : 0.0 }}</h4>
            </div>
          </div>

          <!--/ follower projects rank -->
        </b-card>
      </b-col>

      <b-col v-for="(item, index) in saleQuoteData.winTrades" :key="item.id" cols="3">
        <feather-icon icon="XIcon" v-b-tooltip.hover.top="'Delete'" size="20" class="deleteButton" @click="winTradeDelete(item, index)" />
        <b-card @click="winTradeClick(item, index)" img-alt="Profile Cover Photo" img-top no-body style="height: 230px;" class="card-profile p-1 bg-light-success">
          <h5 class="mt-1">
            {{ item.value }}
          </h5>

          <hr class="mb-2" />

          <!-- follower projects rank -->
          <div class="text-center  justify-content-between align-items-center">
            <div>
              <h6 class="text-muted font-weight-bolder">Appraised Vehicle Price</h6>
              <h3 class="mb-1">${{ item.appraisedVehiclePrice ? formatPrice(item.appraisedVehiclePrice) : 0.0 }}</h3>
            </div>

            <div>
              <h6 class="text-muted font-weight-bolder">Credit Due</h6>
              <h4 class="mb-1">${{ item.creditDue ? formatPrice(item.creditDue) : 0.0 }}</h4>
            </div>
          </div>
          <!--/ follower projects rank -->
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BCardBody, BLink, BImg, BButton, BRow, BCol, BFormGroup, BFormInput, VBToggle, VBTooltip } from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import NewTradeCreate from './NewTradeCreate.vue';
import ExistingTradeSelect from './ExistingTradeSelect.vue';
import purchaseStoreModule from '@/views/purchase/purchaseStoreModule';
import { ref, onUnmounted } from '@vue/composition-api';

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    VBToggle,
    VBTooltip,

    vSelect,
    VueNumericInput,
    ToastificationContent,
    NewTradeCreate,
    ExistingTradeSelect,

    //validation
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const PURCHASE_APP_STORE_MODULE_NAME = 'purchase';
    // Register module
    if (!store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.registerModule(PURCHASE_APP_STORE_MODULE_NAME, purchaseStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PURCHASE_APP_STORE_MODULE_NAME)) store.unregisterModule(PURCHASE_APP_STORE_MODULE_NAME);
    });
  },

  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },

  props: {
    dropInventorys: {
      type: Array,
      required: true,
    },
    saleQuoteData: {
      type: Object,
      required: true,
    },

    dropAll: {
      required: true,
    },
  },

  data() {
    var dropCondition = ['NEW', 'USED'];
    var dropMileageUnit = ['KM', 'MI'];

    return {
      hide: false,
      dropdownLoading: false,
      dropCondition,
      dropMileageUnit,
      modelOptionsVal: [],
      trimOptionsVal: [],
      tradeDataId: null,
      tradeAllQuoteData: [],
      baseURL: store.state.app.baseURL,
      deleteTrades: [],
      isTaskHandlerSidebarActive: false,
    };
  },

  created() {
    this.getTradeQuotes();
  },
  watch: {},

  methods: {
    handleTaskClick() {
      this.isTaskHandlerSidebarActive = true;
    },

    tradeClick(item, index) {
      item.index = index;

      this.tradeDataId = item;

      this.$root.$emit('bv::toggle::collapse', 'new-trade-create');
    },

    winTradeClick(item, index) {
      item.index = index;

      this.$root.$emit('bv::toggle::collapse', 'existing-trade-select');
    },

    getTradeQuotes() {
      store
        .dispatch('purchase/getPurchaseDropDowns')
        .then((response) => {
          response.data.forEach((element) => {
            element.checked = false;
          });
          this.tradeAllQuoteData = response.data;
        })
        .catch((error) => {});
    },

    tradeDelete(item, index) {
      this.saleQuoteData.trades.splice(index, 1);

      if (item.id) {
        this.deleteTrades.push(item.id);

        this.saleQuoteData.deleteTrades = this.deleteTrades;
      }
    },

    winTradeDelete(item, index) {
      this.saleQuoteData.winTrades.splice(index, 1);

      if (item.id) {
        this.deleteTrades.push(item.id);

        this.saleQuoteData.deleteTrades = this.deleteTrades;
      }
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.card-profile:hover {
  transform: scale(1);
  cursor: pointer;
}

.deleteButton {
  position: absolute;

  top: -8px;
  right: 8px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fff;
  background-color: #3178cf;
  cursor: pointer;
  opacity: 0.5;
  z-index: 1;
}

.deleteButton:hover {
  opacity: 1;
}
</style>
