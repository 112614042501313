<template>
  <div>
    <create-quote></create-quote>
  </div>
</template>

<script>
import CreateQuote from './CreateQuote.vue';

export default {
  components: {
    CreateQuote,
  },

  data() {
    return {};
  },
};
</script>
