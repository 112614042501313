<template>
  <validation-observer ref="vehicleRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Description of Vehicle</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col cols="12" md="12">
        <b-form-group label="Vehicle" label-for="vehicle" v-if="!tradeData.id">
          <v-select v-model="tradeData.inventoryId" :options="dropInventorys" :reduce="(val) => val.id" label="value" input-id="customer-data" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- :before-change="validationForm1" -->
    <b-row>
      <b-col cols="12" md="8">
        <b-form-group label="Vehicle Identification Number*" label-for="Vehicle Identification Number">
          <validation-provider #default="{ errors }" name="Vehicle Identification Number" rules="required">
            <b-form-input id="vinNo" @change="vinNoCheck(tradeData.vinNo)" :disabled="tradeData.vehicleId > 0" v-uppercase v-model="tradeData.vinNo" type="text" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Stock Number" label-for="Stock Number">
          <b-form-input id="stockNumber" disabled v-model="tradeData.stockNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Condition*" label-for="condition">
          <validation-provider #default="{ errors }" name="Condition" rules="required">
            <v-select v-model="tradeData.condition" :options="dropCondition" :disabled="tradeData.vehicleId > 0" :state="errors.length > 0 ? false : null" label="title" :clearable="false"> </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group label="Mileage Unit" label-for="mileageUnit">
          <v-select v-model="tradeData.mileageUnit" :disabled="tradeData.vehicleId > 0" :options="dropMileageUnit" :clearable="false" label="title"> </v-select>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Exterior Colour -->
      <b-col cols="12" md="4">
        <b-form-group label="Exterior Colour" label-for="extColour">
          <v-select
            v-model="tradeData.extColour"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'extColour' })"
            :loading="dropdownLoading"
            :disabled="tradeData.vehicleId > 0"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="tradeData.extColour"
            :reduce="(val) => val.id"
            :options="dropAll['extColour']"
            label="value"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Year -->
      <b-col cols="12" md="4">
        <b-form-group label="Model Year*" label-for="year">
          <validation-provider #default="{ errors }" name="Model Year" rules="required">
            <v-select
              v-model="tradeData.modelYear"
              :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'modelYear' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :reduce="(val) => val.id"
              :options="dropAll['modelYear']"
              label="value"
              :clearable="true"
              :disabled="oldCardDisabled || tradeData.vehicleId > 0"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="4">
        <b-form-group label="Mileage" label-for="mileage">
          <b-form-input id="mileage" :disabled="tradeData.vehicleId > 0" v-model="tradeData.mileageValue" type="number" />
        </b-form-group>
      </b-col>

      <!-- Dropdown: Int Colour -->
      <b-col cols="12" md="4">
        <b-form-group label="Interior Colour" label-for="intColour">
          <v-select
            v-model="tradeData.intColour"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'intColour' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="tradeData.intColour"
            :reduce="(val) => val.id"
            :options="dropAll['intColour']"
            label="value"
            :clearable="true"
            :disabled="tradeData.vehicleId > 0"
          >
          </v-select>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Make -->
      <b-col cols="12" md="4">
        <b-form-group label="Make*" label-for="make">
          <validation-provider #default="{ errors }" name="Make" rules="required">
            <v-select
              v-model="tradeData.make"
              :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'make' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :value="tradeData.make"
              :state="errors.length > 0 ? false : null"
              :options="dropAll['make']"
              :reduce="(val) => val.id"
              label="value"
              @input="modelClear()"
              :clearable="true"
              :disabled="oldCardDisabled || tradeData.vehicleId > 0"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!-- Dropdown: Body-->
      <!--   <b-col cols="12" md="4">
        <b-form-group label="Body Type" label-for="body">
          <v-select
            v-model="tradeData.body"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'body' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="tradeData.body"
            :options="dropAll['body']"
            :reduce="(val) => val.id"
            label="value"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>
 -->
      <!-- Dropdown: Fuel Type-->
      <!-- <b-col cols="12" md="4">
        <b-form-group label="Fuel Type" label-for="fuel">
          <v-select
            v-model="tradeData.fuel"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'fuel' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="tradeData.fuel"
            :reduce="(val) => val.id"
            :options="dropAll['fuel']"
            label="value"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col> -->

      <!-- Dropdown: Model -->
      <b-col cols="12" md="4">
        <b-form-group label="Model*" label-for="model">
          <validation-provider #default="{ errors }" name="Model" rules="required">
            <v-select
              v-model="tradeData.model"
              :taggable="true"
              :create-option="(temp) => ({ id: 0, value: temp, who: 'model' })"
              :loading="dropdownLoading"
              @option:created="createNewDropdownValue"
              @option:selected="selectedDropdownValue"
              :value="tradeData.model"
              :state="errors.length > 0 ? false : null"
              :options="modelOptionsVal"
              :reduce="(val) => val.id"
              label="value"
              :clearable="true"
              @input="trimClear()"
              :disabled="!tradeData.make > 0 || oldCardDisabled || tradeData.vehicleId > 0"
            >
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <!--      <b-col cols="12" md="4">
        <b-form-group label="Seating Capacity" label-for="seatingCapacity">
          <vue-numeric-input v-model="tradeData.seatingCapacity" :min="0" controlsType="plusminus" :max="20" align="center" size="normal" className="form-control" width="100%" style="height:2.714rem" :step="1"></vue-numeric-input>
        </b-form-group>
      </b-col> -->

      <!-- Dropdown: Drivetrain Layouts-->
      <!--  <b-col cols="12" md="4">
        <b-form-group label="Drivetrain" label-for="drivetrain">
          <v-select
            v-model="tradeData.drivetrain"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'drivetrain' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="tradeData.drivetrain"
            :options="dropAll['drivetrain']"
            label="value"
            :reduce="(val) => val.id"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col>
 -->
      <!-- Dropdown: Trim -->
      <!-- <b-col cols="12" md="4">
        <b-form-group label="Trim" label-for="trim">
          <v-select
            v-model="tradeData.trim"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'trim' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="tradeData.trim"
            :options="trimOptionsVal"
            :reduce="(val) => val.id"
            label="value"
            :clearable="true"
            :disabled="!tradeData.model > 0"
          >
          </v-select>
        </b-form-group>
      </b-col> -->
      <!-- 
      <b-col cols="12" md="4">
        <b-form-group label="Number of Doors" label-for="numberOfDoors">
          <vue-numeric-input v-model="tradeData.numberOfDoors" :min="0" controlsType="plusminus" :max="20" align="center" size="normal" className="form-control" width="100%" style="height:2.714rem" :step="1"></vue-numeric-input>
        </b-form-group>
      </b-col>
 -->
      <!-- Dropdown: Transmmission Type-->
      <!--  <b-col cols="12" md="4">
        <b-form-group label="Transmission" label-for="transmmission">
          <v-select
            v-model="tradeData.transmmission"
            :taggable="true"
            :create-option="(temp) => ({ id: 0, value: temp, who: 'transmmission' })"
            :loading="dropdownLoading"
            @option:created="createNewDropdownValue"
            @option:selected="selectedDropdownValue"
            :value="tradeData.transmmission"
            :options="dropAll['transmmission']"
            label="value"
            :reduce="(val) => val.id"
            :clearable="true"
          >
          </v-select>
        </b-form-group>
      </b-col> -->
    </b-row>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
import router from '@/router';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VueNumericInput from 'vue-numeric-input';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    VueNumericInput,
    ToastificationContent,
    //validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dropInventorys: {
      type: Array,
      required: true,
    },
    dropTradeQuotes: {
      type: Array,
      required: true,
    },
    tradeData: {
      type: Object,
      required: true,
    },
    dropAll: {
      required: true,
    },
  },
  data() {
    var dropCondition = ['NEW', 'USED'];
    var dropMileageUnit = ['KM', 'MI'];
    var whereId = router.currentRoute.params.id;
    return {
      dropdownLoading: false,
      dropCondition,
      dropMileageUnit,
      modelOptionsVal: [],
      trimOptionsVal: [],
      oldCardDisabled: false,
      whereId,
    };
  },
  directives: {
    uppercase: {
      update(element) {
        element.value = element.value.toUpperCase();
      },
    },
  },
  watch: {
    'tradeData.make': {
      handler: function(id, before) {
        this.makeSelected(this.tradeData.make);
      },
    },
    'tradeData.model': {
      handler: function(id, before) {
        if (this.tradeData.model != null) {
          //  this.msrpClick();
          this.modelSelected(this.tradeData.model);
        }
      },
    },
  },
  methods: {
    createNewDropdownValue(val) {
      this.dropdownLoading = true;
      switch (val.who) {
        case 'extColour':
          if (this.dropAll['extColour'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveExtColour', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['extColour'].unshift(response.data);
                this.tradeData.extColour = response.data.id;
                this.toastMessage('Ext Colour');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'modelYear':
          if (this.dropAll['modelYear'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveModelYear', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['modelYear'].unshift(response.data);
                this.tradeData.modelYear = response.data.id;
                this.toastMessage('Model Year');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'intColour':
          if (this.dropAll['intColour'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveIntColour', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['intColour'].unshift(response.data);
                this.tradeData.intColour = response.data.id;
                this.toastMessage('Interior Colour');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'make':
          if (this.dropAll['make'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveMakes', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['make'].unshift(response.data);
                this.tradeData.make = response.data.id;
                this.toastMessage('Make');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'body':
          if (this.dropAll['body'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveBodyType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['body'].unshift(response.data);
                this.tradeData.body = response.data.id;
                this.toastMessage('Body Type');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'fuel':
          if (this.dropAll['fuel'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveFuelType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['fuel'].unshift(response.data);
                this.tradeData.fuel = response.data.id;
                this.toastMessage('Fuel Type');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'model':
          if (this.modelOptionsVal.find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveModel', { value: val.value.toUpperCase().trim(), makeId: this.tradeData.make }).then((response) => {
              if (response.status == 201) {
                this.modelOptionsVal.unshift(response.data);
                this.tradeData.model = response.data.id;
                this.tradeData.modelValue = response.data.value;
                this.toastMessage('Model');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'drivetrain':
          if (this.dropAll['drivetrain'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveDrivetrain', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['drivetrain'].unshift(response.data);
                this.tradeData.drivetrain = response.data.id;
                this.toastMessage('Drivetrain');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'trim':
          if (this.trimOptionsVal.find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveTrim', { value: val.value.toUpperCase().trim(), modelId: this.tradeData.model }).then((response) => {
              if (response.status == 201) {
                this.trimOptionsVal.unshift(response.data);
                this.tradeData.trim = response.data.id;
                this.tradeData.trimValue = response.data.value;
                this.toastMessage('Trim');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'transmmission':
          if (this.dropAll['transmmission'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveTransmmissionType', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['transmmission'].unshift(response.data);
                this.tradeData.transmmission = response.data.id;
                this.toastMessage('Transmission');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'vinNo':
          if (this.dropAll['vinNo'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveVinNo', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['vinNo'].unshift(response.data);
                this.tradeData.vinNo = response.data.id;
                this.toastMessage('Vehicle Identification Number');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'origenalMarket':
          if (this.dropAll['origenalMarket'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveOrigenalMarket', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['origenalMarket'].unshift(response.data);
                this.tradeData.origenalMarket = response.data.id;
                this.toastMessage('Original Market Specs');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'msrpCurrency':
          if (this.dropAll['baseMsrpCurrency'].find((x) => x.value.toUpperCase() == val.value.toUpperCase()) == undefined) {
            store.dispatch('purchase/saveBaseMsrpCurrency', val.value.toUpperCase().trim()).then((response) => {
              if (response.status == 201) {
                this.dropAll['baseMsrpCurrency'].unshift(response.data);
                this.tradeData.msrpCurrency = response.data.id;
                this.toastMessage('Original Market Specs');
                this.dropdownLoading = false;
              }
            });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'msrpValueOptions':
          if (this.msrpValueOptions.find((x) => x.option.toUpperCase() == val.option.toUpperCase()) == undefined) {
            store
              .dispatch('purchase/saveMsrpOption', {
                option: val.option.toUpperCase().trim(),
                makeId: this.tradeData.make,
                modelId: this.tradeData.model,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.msrpValueOptions.unshift(response.data);
                  this.msrpValueOption = response.data.id;
                  this.toastMessage('Msrp Options Name');
                  this.dropdownLoading = false;
                }
              });
          } else {
            this.dropdownLoading = false;
          }
          break;
        case 'msrpPriceOptions':
          if (val.price >= 0) {
            if (this.msrpPriceOptions.find((x) => x.price == val.price) == undefined) {
              store
                .dispatch('purchase/saveMsrpPrice', {
                  price: val.price.trim(),
                  optionId: this.msrpValueOption,
                })
                .then((response) => {
                  if (response.status == 201) {
                    this.msrpPriceOptions.unshift(response.data);
                    this.msrpPriceOption = response.data.id;
                    this.toastMessage('Msrp Options Price');
                    this.dropdownLoading = false;
                  }
                });
            } else {
              this.dropdownLoading = false;
            }
          } else {
            this.dropdownLoading = false;
            this.msrpPriceOption = null;
          }
          break;
        case 'accessioreValueOptions':
          if (this.accessioreValueOptions.find((x) => x.option.toUpperCase() == val.option.toUpperCase()) == undefined) {
            store
              .dispatch('purchase/saveAccessioreOption', {
                option: val.option.toUpperCase().trim(),
                makeId: this.tradeData.make,
                modelId: this.tradeData.model,
              })
              .then((response) => {
                if (response.status == 201) {
                  this.accessioreValueOptions.unshift(response.data);
                  this.accessioreValueOptions.id = response.data.id;
                  this.toastMessage('Accessorie Options Name');
                  this.dropdownLoading = false;
                }
              });
          } else {
            this.dropdownLoading = false;
          }
          break;
        default:
          break;
      }
    },
    selectedDropdownValue(val) {
      switch (val.who) {
        case 'extColour':
          this.tradeData.extColour = this.dropAll['extColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.extColour != undefined && this.tradeData.extColour != 0) {
            this.tradeData.extColour = this.dropAll['extColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'modelYear':
          this.tradeData.modelYear = this.dropAll['modelYear'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.modelYear != undefined && this.tradeData.modelYear != 0) {
            this.tradeData.modelYear = this.dropAll['modelYear'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'intColour':
          this.tradeData.intColour = this.dropAll['intColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.intColour != undefined && this.tradeData.intColour != 0) {
            this.tradeData.intColour = this.dropAll['intColour'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'make':
          this.tradeData.make = this.dropAll['make'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.make != undefined && this.tradeData.make != 0) {
            this.tradeData.make = this.dropAll['make'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'body':
          this.tradeData.body = this.dropAll['body'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.body != undefined && this.tradeData.body != 0) {
            this.tradeData.body = this.dropAll['body'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'fuel':
          this.tradeData.fuel = this.dropAll['fuel'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.fuel != undefined && this.tradeData.fuel != 0) {
            this.tradeData.fuel = this.dropAll['fuel'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'model':
          this.tradeData.model = this.modelOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.model != undefined && this.tradeData.model != 0) {
            this.tradeData.model = this.modelOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          this.tradeData.modelValue = val.value;
          break;
        case 'drivetrain':
          this.tradeData.drivetrain = this.dropAll['drivetrain'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.drivetrain != undefined && this.tradeData.drivetrain != 0) {
            this.tradeData.drivetrain = this.dropAll['drivetrain'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'trim':
          this.tradeData.trim = this.trimOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.trim != undefined && this.tradeData.trim != 0) {
            this.tradeData.trim = this.trimOptionsVal.filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          this.tradeData.trimValue = val.value;
          break;
        case 'transmmission':
          this.tradeData.transmmission = this.dropAll['transmmission'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.transmmission != undefined && this.tradeData.transmmission != 0) {
            this.tradeData.transmmission = this.dropAll['transmmission'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'vinNo':
          this.tradeData.vinNo = this.dropAll['vinNo'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.vinNo != undefined && this.tradeData.vinNo != 0) {
            this.tradeData.vinNo = this.dropAll['vinNo'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'origenalMarket':
          this.tradeData.origenalMarket = this.dropAll['origenalMarket'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.origenalMarket != undefined && this.tradeData.origenalMarket != 0) {
            this.tradeData.origenalMarket = this.dropAll['origenalMarket'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'msrpCurrency':
          this.tradeData.msrpCurrency = this.dropAll['baseMsrpCurrency'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0];
          if (this.tradeData.msrpCurrency != undefined && this.tradeData.msrpCurrency != 0) {
            this.tradeData.msrpCurrency = this.dropAll['baseMsrpCurrency'].filter((x) => x.value.toUpperCase() == val.value.toUpperCase())[0].id;
          }
          break;
        case 'msrpValueOptions':
          this.msrpValueOption = this.msrpValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0];
          if (this.msrpValueOption != undefined && this.msrpValueOption != 0) {
            this.msrpValueOption = this.msrpValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0].id;
          }
          this.msrpMakeSelected(this.msrpValueOption);
          break;
        case 'msrpPriceOptions':
          this.msrpPriceOption = this.msrpPriceOptions.filter((x) => x.price == val.price)[0];
          if (this.msrpPriceOption != undefined && this.msrpPriceOption != 0) {
            this.msrpPriceOption = this.msrpPriceOptions.filter((x) => x.price == val.price)[0].id;
          }
          break;
        case 'accessioreValueOptions':
          this.accessioreValueOptions.id = this.accessioreValueOptions.filter((x) => x.option.toUpperCase() == val.option.toUpperCase())[0];
          break;
        default:
          break;
      }
    },
    makeSelected(k) {
      if (k != null || k != undefined) {
        this.dropdownLoading = true;
        store
          .dispatch('purchase/getModelByMake', k)
          .then((response) => {
            if (response.data[0] != undefined) {
              this.modelOptionsVal = response.data;
              if (this.tradeData.model) {
                this.tradeData.modelValue = this.modelOptionsVal.find((x) => x.id == this.tradeData.model).value;
              }
            } else {
              this.modelOptionsVal = [];
            }
            this.dropdownLoading = false;
          })
          .catch((e) => {
            this.dropdownLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Model list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    modelSelected(k) {
      /*  if (c == 1) {
        this.tradeData.trim = '';
        this.trimOptionsVal = [];
      }*/
      if (k != null || k != undefined) {
        this.dropdownLoading = true;
        store
          .dispatch('purchase/getTrimByModel', k)
          .then((response) => {
            this.formShow = false;
            if (response.data[0] != undefined) {
              this.trimOptionsVal = response.data;
              if (this.tradeData.trim) {
                this.tradeData.trimValue = this.trimOptionsVal.find((x) => x.id == this.tradeData.trim).value;
              }
            } else {
              this.trimOptionsVal = [];
            }
            this.dropdownLoading = false;
          })
          .catch((e) => {
            this.dropdownLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching Trim list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    vinNoCheck(k) {
      if (k != '') {
        this.$emit('update:formShow', true);
        store
          .dispatch('purchase/vinNoCheck', { vinNo: k })
          .then((response) => {
            this.$emit('update:formShow', false);
            if (response.data.res == 0) {
              //start
              //NEXT
              this.tradeData.condition = null;
              this.tradeData.modelYear = null;
              this.tradeData.make = null;
              this.tradeData.stockNumber = null;
              this.tradeData.model = null;
              this.oldCardDisabled = false;
              //finish
            } else if (response.data.res == 1) {
              this.$swal({
                //   title: 'Information',
                text: 'This vehicle has been previously sold!',
                icon: 'info',
                showCancelButton: false,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then((result) => {
                //result
              });
            } else {
              this.$swal({
                //   title: 'Information',
                text: 'The VIN number is already registered with an existing vehicle in the inventory. Do you want to proceed to the vehicle details?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Vehicle Details',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  //YES
                  this.tradeData.condition = response.data.data.condition;
                  this.tradeData.modelYear = response.data.data.modelYear;
                  this.tradeData.make = response.data.data.make;
                  this.tradeData.stockNumber = response.data.data.stockNumber;
                  this.tradeData.model = response.data.data.model;
                  this.oldCardDisabled = true;
                } else {
                  this.tradeData.vinNo = null;
                  this.tradeData.condition = null;
                  this.tradeData.modelYear = null;
                  this.tradeData.make = null;
                  this.tradeData.stockNumber = null;
                  this.tradeData.model = null;
                  this.oldCardDisabled = false;
                }
              });
            }
          })
          .catch((e) => {
            this.$emit('update:formShow', false);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching check control',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          });
      }
    },
    modelClear() {
      this.tradeData.model = null;
      this.tradeData.trim = null;
    },
    trimClear() {
      this.tradeData.trim = null;
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Add Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>
