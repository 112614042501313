import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    fetchInventoryView(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`leaseInventoryView/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('customer', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    addCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('customer', customerData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    saveInceptionFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveInceptionFile', data)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    addInventory(ctx, inventoryData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventory', inventoryData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //GetDropCustomer

    getCustomerDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('customerDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getCustomerDropDownId(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`customerDrop/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //GetDropInventory

    getInventoryDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryInStockDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPurchaseDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('showTradeInDropAll')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    showOffroadDrop(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showOffroadDrop/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    vinNoCheck(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('vinNoPurchaseCheck', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //AllDropDown

    getAllDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('alldropdown')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMsrpOption() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('msrpoption')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMsrpPrice() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('msrpprice')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMsrpOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('msrpoption', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveQuote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveInception', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateQuote(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateInception/${data.id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfdownloadPayment(ctx, paymentId) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('downloadPaymentPdf', { id: paymentId }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    getAllQuote(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('allInceptions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllArchiveQuote(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('allArchiveInceptions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`singleInception/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPreviewQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`previewInceptionTest/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getArchivePreviewQuoteById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`previewArchiveInception/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    imageUpload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('quotegallery', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfArchiveDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfarchivedownloadSale', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMsrpPrice(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('msrpprice', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveAccessioreOption(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('accessioreoption', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveAdditional(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('leaseAdditional', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccessioreOption() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('accessioreoption')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAccessiorePrice() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('accessioreprice')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getModelByMake(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('model/' + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saleSendToArchive(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saleSendToArchive', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //getModelByModel

    getTrimByModel(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('trim/' + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //Add inventory
    addInventory(ctx, inventoryData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inventory', inventoryData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveModelYear(ctx, modelYear) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('modelYear', { value: modelYear })

          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveMakes(ctx, make) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('make', { value: make })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveModel(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('model', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    nextIdQuotes() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('nextInceptionId')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    inceptionCheck(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('inceptionCheck', { id: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveDrivetrain(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('drivetrain', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveTrim(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('trim', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveTransmmissionType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('transmmissiontype', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveBodyType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('body', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveFuelType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('fueltype', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveExtColour(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('extcolour', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveIntColour(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('intcolour', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveVinNo(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('vinno', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    saveOrigenalMarket(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('origenalmarketspecs', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveBaseMsrpCurrency(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('basemsrpcurrency', { value: data })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //add purchase
    addPurchase(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('purchase', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPurchases(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('purchase', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getPurchaseByIdPreview(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`purchasePreview/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPurchaseById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`purchase/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateQuoteStatus(ctx, val) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`inceptionStatus/${val.id}`, val)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    fetchSaleColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('sale_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    saveOffroadFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveOffroadFile', data)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    returnGetInventoryDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryTradeDrop() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryTradeDrop')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getInventoryDropDownId(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`inventoryDrop/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTradeInventorySelectId(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`tradeInventorySelectId/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //AllDropDown

    offroadAllDropDown() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('offroadAllDropDown')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    saveOffroad(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveOffroad', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateOffroad(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateOffroad/${data.id}`, data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllOffroad(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listOffroads', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllArchiveOffroad(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('listOffroads', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getOffroadById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`singleOffroad/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getPreviewOffroadById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`previewOffroad/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getArchivePreviewOffroadById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`previewOffroad/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    offroadImageUpload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('offroadGallery', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    offroadPdfDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadOffroad', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    offroadPdfArchiveDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfArchivedownloadOffroad', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    offroadSendToArchive(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('sendToArchiveOffroad', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    nextIdOffroad() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('nextOffroadId')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    //add purchase
    addOffroad(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('offroad', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getOffroads(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('offroad', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getOffroadByIdPreview(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`offroadPreview/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    inventoryPurchaseStatus(ctx, val) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`inventoryPurchaseStatus/${val.id}`, val)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    getOffroadDropDowns() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('showTradeInDropAll')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    vinNoCheck(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('vinNoOffroadCheck', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateOffroadStatus(ctx, val) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`offroadStatus/${val.id}`, val)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchPurchaseColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchase_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    paymentStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`paymentStatus`, params)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchPayment() {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('leasePayments')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchPaymentById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`showPayment/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    savePayment(ctx, paymentData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('savePayment', paymentData)
          .then((response) => {
            resolve(response);
            //     showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    fetchLeaseInventorys(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('leaseAllInventory', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    leasePaymentsInventory(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`leasePaymentsInventory/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    pdfDownload(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('pdfdownloadInception', { id: data }, { responseType: 'blob' })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
