<template>
  <validation-observer ref="purchaserRules">
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Purchaser Identification</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <b-row>
      <b-col lg="10" md="10" sm="12">
        <b-form-group label="Customer" label-for="customer">
          <v-select v-model="saleQuoteData.purchaserIdentification.customerId" @input="purchaserCustomerSelect" :options="dropCustomers" :reduce="(val) => val.id" label="value" input-id="customer-data" :clearable="true"> </v-select>
        </b-form-group>
      </b-col>

      <b-col lg="2" md="2" sm="12" class="text-center" v-if="!saleQuoteData.purchaserIdentification.customerId" v-show="$Can('customer_create')">
        <b-form-group class="mt-2" label-for="title">
          <b-button @click="customerEdit = !customerEdit" block variant="relief-dark" class="btn-icon">
            New Customer
          </b-button>
        </b-form-group>
      </b-col>

      <b-col lg="2" md="2" sm="12" class="text-center" v-if="saleQuoteData.purchaserIdentification.customerId && saleQuoteData.id" v-show="$Can('customer_edit')">
        <b-form-group class="mt-2" label-for="title">
          <b-button @click="customerEdit = !customerEdit" block variant="relief-dark" class="btn-icon">
            Edit Customer
          </b-button>
        </b-form-group>
      </b-col>

      <b-col lg="2" md="2" sm="12" class="text-center" v-if="saleQuoteData.purchaserIdentification.customerId && !saleQuoteData.id" v-show="$Can('customer_edit')">
        <b-form-group class="mt-2" label-for="title">
          <b-button @click="customerEdit = !customerEdit" block variant="relief-dark" class="btn-icon">
            Edit Customer
          </b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="container mt-1" v-show="customerEdit" v-if="saleQuoteData.purchaserIdentification.customerId">
      <table>
        <tr>
          <th class="pr-2">Seller Type:</th>
          <td>
            <span v-if="this.saleQuoteData.purchaserIdentification.sellerType == 1"> Individual</span>
            <span v-else> Business</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong v-if="this.saleQuoteData.purchaserIdentification.sellerType == 1">First Name:</strong>
            <strong v-else>Business Name:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.firstName }}</span>
          </td>
        </tr>

        <tr v-if="this.saleQuoteData.purchaserIdentification.sellerType == 2">
          <th class="pr-2">
            <strong class="mr-2">Contact Person:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.contactPerson }}</span>
          </td>
        </tr>

        <tr v-if="this.saleQuoteData.purchaserIdentification.sellerType == 2">
          <th class="pr-2">
            <strong class="mr-2">Contact Info:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.contactInfo }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2" v-if="this.saleQuoteData.purchaserIdentification.sellerType == 1">Last Name:</strong>
            <strong class="mr-2" v-else>Operating Name:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.lastName }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Driver's License Number:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.driverLicenseNumber }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">G.S.T Registration:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.gst }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Phone Number:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.phoneNumber }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Email:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.email }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Other ID Number:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.otherIdNumber }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">P.S.T Registration:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.pst }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Unit Number:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.unitNumber }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Street Address:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.streetAddress }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Date of Birth:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.dateOfBirth }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Dealer Registration:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.dealer }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">City:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.city }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Province:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.provence }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Postal Code:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.postalCode }}</span>
          </td>
        </tr>

        <tr>
          <th class="pr-2">
            <strong class="mr-2">Country:</strong>
          </th>
          <td>
            <span> {{ this.saleQuoteData.purchaserIdentification.country }}</span>
          </td>
        </tr>
      </table>
    </b-row>

    <b-row v-show="!customerEdit">
      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="sellerType" class="border-bottom">Seller Type*</label>
          <validation-provider #default="{ errors }" name="Seller Type" rules="required">
            <b-form-radio-group class="border-bottom pb-1">
              <b-form-radio v-model="saleQuoteData.purchaserIdentification.sellerType" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " value="1">
                Individual
              </b-form-radio>
              <b-form-radio v-model="saleQuoteData.purchaserIdentification.sellerType" :state="errors.length > 0 ? false : null" name="seller-type" class="mr-2 " value="2">
                Business
              </b-form-radio>
            </b-form-radio-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <validation-provider :name="saleQuoteData.purchaserIdentification.sellerType == 2 ? 'Business Name' : 'First Name'" #default="{ errors }" rules="required">
            <label v-if="saleQuoteData.purchaserIdentification.sellerType == 2" for="firstName">Business Name*</label>
            <label v-else for="firstName">First Name*</label>
            <b-form-input id="firstName" v-model="saleQuoteData.purchaserIdentification.firstName" :state="errors.length > 0 ? false : null" type="text" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label v-if="saleQuoteData.purchaserIdentification.sellerType == 2" for="lastName">Operating Name</label>
          <label v-else for="lastName">Last Name </label>
          <b-form-input id="lastName" v-model="saleQuoteData.purchaserIdentification.lastName" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3" v-if="saleQuoteData.purchaserIdentification.sellerType == 2">
        <b-form-group>
          <label for="contactPerson">Contact Person</label>

          <b-form-input id="contactPerson" v-model="saleQuoteData.purchaserIdentification.contactPerson" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3" v-if="saleQuoteData.purchaserIdentification.sellerType == 2">
        <b-form-group>
          <label for="contactInfo">Contact Info</label>
          <b-form-input id="contactInfo" v-model="saleQuoteData.purchaserIdentification.contactInfo" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="driverLicenseNumber">Driver's License Number</label>
          <b-form-input id="driverLicenseNumber" v-model="saleQuoteData.purchaserIdentification.driverLicenseNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="gst">G.S.T Registration</label>
          <b-form-input id="gst" v-model="saleQuoteData.purchaserIdentification.gst" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="phoneNumber">Phone Number</label>

          <b-form-input id="phoneNumber" v-model="saleQuoteData.purchaserIdentification.phoneNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="email">Email</label>
          <b-form-input id="email" v-model="saleQuoteData.purchaserIdentification.email" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="otherIdNumber">Other ID Number</label>
          <b-form-input id="otherIdNumber" v-model="saleQuoteData.purchaserIdentification.otherIdNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="pst">P.S.T Registration</label>
          <b-form-input id="pst" v-model="saleQuoteData.purchaserIdentification.pst" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="unitNumber">Unit Number</label>
          <b-form-input id="unitNumber" v-model="saleQuoteData.purchaserIdentification.unitNumber" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="6">
        <b-form-group>
          <label for="streetAddress">Street Address</label>
          <b-form-input id="streetAddress" v-model="saleQuoteData.purchaserIdentification.streetAddress" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="dateOfBirth">Date of Birth</label>
          <flat-pickr id="dateOfBirth" class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="saleQuoteData.purchaserIdentification.dateOfBirth" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="dealer">Dealer Registration</label>
          <b-form-input id="dealer" v-model="saleQuoteData.purchaserIdentification.dealer" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="city">City</label>
          <b-form-input id="city" v-model="saleQuoteData.purchaserIdentification.city" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="provence">Province</label>
          <b-form-input id="provence" v-model="saleQuoteData.purchaserIdentification.provence" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="postalCode">Postal Code</label>
          <b-form-input id="postalCode" v-model="saleQuoteData.purchaserIdentification.postalCode" type="text" />
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" md="4" xl="3">
        <b-form-group>
          <label for="country">Country</label>
          <b-form-input id="country" v-model="saleQuoteData.purchaserIdentification.country" type="text" />
        </b-form-group>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormRadioGroup } from 'bootstrap-vue';
import store from '@/store';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Cleave from 'vue-cleave-component';
import 'cleave.js/dist/addons/cleave-phone.us';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormRadioGroup,

    ToastificationContent,
    Cleave,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
  },

  props: {
    saleQuoteData: {
      type: Object,
      required: true,
    },
    dropCustomers: {
      type: Array,
      required: true,
    },
    purchaserCustomerData: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      customerEdit: true,
    };
  },

  watch: {
    purchaserCustomerData: function(val) {
      this.saleQuoteData.purchaserIdentification.sellerType = val.sellerType;
      this.saleQuoteData.purchaserIdentification.phoneNumber = val.phoneNumber;
      this.saleQuoteData.purchaserIdentification.firstName = val.firstName;
      this.saleQuoteData.purchaserIdentification.lastName = val.lastName;
      this.saleQuoteData.purchaserIdentification.email = val.email;
      this.saleQuoteData.purchaserIdentification.unitNumber = val.unitNumber;
      this.saleQuoteData.purchaserIdentification.provence = val.provence;
      this.saleQuoteData.purchaserIdentification.streetAddress = val.streetAddress;
      this.saleQuoteData.purchaserIdentification.city = val.city;
      this.saleQuoteData.purchaserIdentification.postalCode = val.postalCode;
      this.saleQuoteData.purchaserIdentification.country = val.country;
      this.saleQuoteData.purchaserIdentification.gst = val.gst;
      this.saleQuoteData.purchaserIdentification.pst = val.pst;
      this.saleQuoteData.purchaserIdentification.dealer = val.dealer;
      this.saleQuoteData.purchaserIdentification.driverLicenseNumber = val.driverLicenseNumber;
      this.saleQuoteData.purchaserIdentification.otherIdNumber = val.otherIdNumber;
      this.saleQuoteData.purchaserIdentification.dateOfBirth = val.dateOfBirth;
      this.saleQuoteData.purchaserIdentification.contactPerson = val.contactPerson;
      this.saleQuoteData.purchaserIdentification.contactInfo = val.contactInfo;
      this.saleQuoteData.purchaserIdentification.customerId = val.id;
    },
  },

  computed: {},

  methods: {
    purchaserCustomerSelect(id) {
      this.customerEdit = true;
      if (id != null) {
        store
          .dispatch('testlease/getCustomerDropDownId', id)
          .then((response) => {
            this.saleQuoteData.purchaserIdentification.sellerType = response.data.sellerType;
            this.saleQuoteData.purchaserIdentification.phoneNumber = response.data.phoneNumber;
            this.saleQuoteData.purchaserIdentification.firstName = response.data.firstName;
            this.saleQuoteData.purchaserIdentification.lastName = response.data.lastName;
            this.saleQuoteData.purchaserIdentification.email = response.data.email;

            this.saleQuoteData.purchaserIdentification.unitNumber = response.data.unitNumber;
            this.saleQuoteData.purchaserIdentification.provence = response.data.provence;
            this.saleQuoteData.purchaserIdentification.streetAddress = response.data.streetAddress;
            this.saleQuoteData.purchaserIdentification.city = response.data.city;
            this.saleQuoteData.purchaserIdentification.postalCode = response.data.postalCode;
            this.saleQuoteData.purchaserIdentification.country = response.data.country;
            this.saleQuoteData.purchaserIdentification.gst = response.data.gst;
            this.saleQuoteData.purchaserIdentification.pst = response.data.pst;
            this.saleQuoteData.purchaserIdentification.dealer = response.data.dealer;

            this.saleQuoteData.purchaserIdentification.driverLicenseNumber = response.data.driverLicenseNumber;
            this.saleQuoteData.purchaserIdentification.otherIdNumber = response.data.otherIdNumber;
            this.saleQuoteData.purchaserIdentification.dateOfBirth = response.data.dateOfBirth;
            this.saleQuoteData.purchaserIdentification.contactPerson = response.data.contactPerson;
            this.saleQuoteData.purchaserIdentification.contactInfo = response.data.contactInfo;

            this.saleQuoteData.purchaserIdentification.customerId = response.data.id;
          })
          .catch((error) => {});
      } else {
        this.saleQuoteData.purchaserIdentification.sellerType = null;
        this.saleQuoteData.purchaserIdentification.phoneNumber = null;
        this.saleQuoteData.purchaserIdentification.firstName = null;
        this.saleQuoteData.purchaserIdentification.lastName = null;
        this.saleQuoteData.purchaserIdentification.email = null;

        this.saleQuoteData.purchaserIdentification.unitNumber = null;
        this.saleQuoteData.purchaserIdentification.provence = null;
        this.saleQuoteData.purchaserIdentification.streetAddress = null;
        this.saleQuoteData.purchaserIdentification.city = null;
        this.saleQuoteData.purchaserIdentification.postalCode = null;
        this.saleQuoteData.purchaserIdentification.country = null;
        this.saleQuoteData.purchaserIdentification.gst = null;
        this.saleQuoteData.purchaserIdentification.pst = null;
        this.saleQuoteData.purchaserIdentification.dealer = null;

        this.saleQuoteData.purchaserIdentification.contactPerson = null;
        this.saleQuoteData.purchaserIdentification.contactInfo = null;

        this.saleQuoteData.purchaserIdentification.driverLicenseNumber = null;
        this.saleQuoteData.purchaserIdentification.otherIdNumber = null;
        this.saleQuoteData.purchaserIdentification.dateOfBirth = null;
      }
    },
  },
};
</script>
