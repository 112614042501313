<template>
  <div>
    <b-row>
      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Vehicle Appraisal Photos</th>
          </tr>
        </thead>
      </table>
    </b-row>

    <div class="mb-5">
      <b-form-file placeholder="Choose a file or drop it here..." accept="image/*" type="file" multiple @input="imageSelected" v-model="tempImage" class="mt-2" drop-placeholder="Drop file here..." />
    </div>

    <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
      <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
    </b-col>

    <image-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
    <!-- <b-overlay :show="formShow" rounded="sm" no-fade> -->
    
    <b-container fluid class="p-2">
      <b-row v-if="tradeData.quoteGallery.length > 0">
        <b-col xs="12" sm="6" md="4" lg="3" class="mb-2" v-for="(image, index) in tradeData.quoteGallery" :key="image.name">
          <feather-icon icon="XIcon" v-b-tooltip.hover.top="'Delete'" size="20" class="deleteButton" @click="imageDelete(index)" />
          <feather-icon icon="CropIcon" v-b-tooltip.hover.top="'Crop'" size="20" class="cropButton" @click="imageCrop(index)" />
          <b-media-aside @click="imageClick(baseURL + image.name)">
            <b-img-lazy thumbnail fluid v-bind="mainProps" ref="refPreviewEl" :src="baseURL + image.name" style="cursor:zoom-in" />
          </b-media-aside>
        </b-col>
      </b-row>
    </b-container>
    
    <!-- </b-overlay> -->
  </div>
</template>

<script>
import { BProgress, BRow, BCol, BFormFile, BContainer, BMediaAside, BImg, BButton, BOverlay, VBTooltip } from 'bootstrap-vue';
import store from '@/store';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import imageCropper from '@core/components/image-cropper/ImageCropper.vue';
import Ripple from 'vue-ripple-directive';
import axiosIns from '@/libs/axios';
export default {
  components: {
    BProgress,
    BRow,
    BCol,
    BFormFile,
    BContainer,
    BMediaAside,
    BImg,
    BButton,
    BOverlay,
    VBTooltip,
    ToastificationContent,
    BCardActions,
    imageCropper,
    //images
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    tradeData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tempImage: [],
      baseURL: store.state.app.baseURL,
      selectedImage: null,
      croppedImage: null,
      formShow: false,
      percentCompleted: 0,
      tempCropImageIndex: null,
      mainProps: {
        blankColor: '#bbb',
        width: 600,
        height: 400,
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    imageSelected(event) {
      event.forEach((element) => {
        this.formShow = true;
        // const file = event.target.files ? event.target.files[0] : null;
        let formData = new FormData();
        formData.append('file', element);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        axiosIns
          .post('quotegallery', formData, config)
          .then((response) => {
            this.tradeData.quoteGallery.push({
              name: response.data,
              id: 0,
            });
            this.tempImage = null;
            this.selectedImage = null;
            this.formShow = false;
          })
          .catch((error) => console.log(error));
      });
    },
    imageCropped(croppedImage) {
      if (croppedImage != null) {
        this.formShow = true;
        let formData = new FormData();
        formData.append('file', croppedImage);
        var oldImageId = this.tradeData.quoteGallery[this.tempCropImageIndex].id;
        this.tradeData.deleteImages.push(this.tradeData.quoteGallery[this.tempCropImageIndex].id);
        this.tradeData.quoteGallery.splice(this.tempCropImageIndex, 1);
        formData.append('imageId', oldImageId);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        axiosIns
          .post('quotegalleryCrop', formData, config)
          .then((response) => {
            this.tradeData.quoteGallery.push({
              name: response.data,
              id: 0,
            });
            this.tempImage = null;
            this.selectedImage = null;
            this.formShow = false;
            this.tempCropImageIndex = null;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Cropped Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Not Successful',
                text: '⛔ Image Upload Not Successful',
                icon: 'ThumbsDownIcon',
                variant: 'danger',
              },
            });
          });
      } else {
        this.tempImage = null;
        this.selectedImage = null;
      }
    },
    imageClick(url) {
      this.$swal({
        imageUrl: url,
        imageWidth: 1000,
        imageHeight: 600,
        showConfirmButton: false,
        width: '1000px',
      });
    },
    imageDelete(item) {
      this.tradeData.deleteImages.push(this.tradeData.quoteGallery[item].id);
      this.tradeData.quoteGallery.splice(item, 1);
    },
    imageCrop(item) {
      var imgName = this.baseURL + this.tradeData.quoteGallery[item].name;
      this.tempCropImageIndex = item;
      if (imgName != null) {
        fetch(imgName).then((res) => {
          const vsm = this;
          this.getBase64Image(res.url, function(base64image) {
            vsm.getURL(base64image);
          });
        });
      }
    },
    getURL(img) {
      this.selectedImage = img;
    },
    getBase64Image(imgUrl, callback) {
      var img = new Image();
      img.onload = function() {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL('image/png');
        callback(dataURL);
      };
      img.setAttribute('crossOrigin', 'anonymous'); //
      img.src = imgUrl;
    },
  },
};
</script>

<style scoped>
.deleteButton {
  position: absolute;
  top: -8px;
  right: 18px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fff;
  background-color: #3178cf;
  cursor: pointer;
  opacity: 0.5;
}
.deleteButton:hover {
  opacity: 1;
}
.cropButton {
  position: absolute;
  top: -8px;
  right: 42px;
  margin: 2px;
  padding: 3px;
  border-radius: 6px;
  color: #fff;
  background-color: #1a1c1f;
  cursor: pointer;
  opacity: 0.5;
}
.cropButton:hover {
  opacity: 1;
}
</style>