<template>
  <validation-observer ref="commentsRules">
    <div>
      <b-row>
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Comments, Promises, Restrictions, Limitations and Conditions</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group>
            <label for="vinNo">Quote Number</label>
            <b-form-input id="vinNo" disabled v-model="saleQuoteData.quoteNumber" type="text" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Expected Completion Date" :rules="wherefrom == 'sales-validation-edit' ? 'required' : ''">
              <label for="expectedDate">Expected Completion Date</label>
              <flat-pickr class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="saleQuoteData.completionDate" />
              <small class="text-danger" v-if="saleQuoteData.completionDate == null && wherefrom == 'sales-validation-edit'">The Expected Completion Date is required </small>
              <small class="text-danger" v-else>{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Quote Date" :rules="wherefrom == 'sales-validation-edit' ? 'required' : ''">
              <label for="quoteDate">Quote Date</label>
              <flat-pickr class="form-control" :config="{ dateFormat: 'd-m-Y' }" placeholder="DD-MM-YYYY" v-model="saleQuoteData.quoteDate" />
              <small class="text-danger" v-if="saleQuoteData.quoteDate == null && wherefrom == 'sales-validation-edit'">The Quote Date is required </small>
              <small class="text-danger" v-else>{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <label for="paymentDay">Payment Day</label>
            <v-select v-model="saleQuoteData.paymentDay" :value="saleQuoteData.paymentDay" :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]" :clearable="true"> </v-select>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <label for="odometer">Odometer</label>
            <b-form-input id="odometer" v-model="saleQuoteData.odometer" type="number" />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="6">
          <b-form-group>
            <validation-provider #default="{ errors }" name="Expected Receiving Location" :rules="wherefrom == 'sales-validation-edit' ? 'required' : ''">
              <label for="location">Expected Receiving Location</label>
              <b-form-input id="location" v-model="saleQuoteData.location" type="text" />
              <small class="text-danger" v-if="saleQuoteData.quoteDate == null && wherefrom == 'sales-validation-edit'">The Expected Receiving Location is required </small>
              <small class="text-danger" v-else>{{ errors[0] }} </small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12">
          <label for="textarea-default">Comments, Promises, Restrictions, Limitations and Conditions</label>
          <b-form-textarea v-model="saleQuoteData.comments" id="textarea-default" rows="5" />
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormRadio, BInputGroup, BFormInput, BFormTextarea } from 'bootstrap-vue';
import store from '@/store';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import router from '@/router';
import vSelect from 'vue-select';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadio,
    BInputGroup,
    BFormInput,
    BFormTextarea,

    //validation
    ValidationProvider,
    ValidationObserver,

    ToastificationContent,
    vSelect,
    flatPickr,
  },

  props: {
    saleQuoteData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dropdownLoading: false,
      wherefrom: router.currentRoute.name,
    };
  },

  watch: {},

  computed: {},

  methods: {},
};
</script>
